import React from 'react'
import { Button } from '../Button'
import { useGetMediaById } from '../../service'
import { Education } from '../../types'

export const EducationLink = ({ education }: { education: Education }) => {
  const { data: pdf } = useGetMediaById(education.document)

  return (
    <>
      <h4 className="font-varela text-brand-dark-purple text-xl text-left font-semibold mb-3">
        {education.title}
      </h4>
      <p className="font-varela text-brand-dark-blue text-lg mb-5">
        {education.content}
      </p>
      <Button
        as="a"
        href={pdf?.source_url || education.link}
        className="flex flex-row items-center justify-center"
        variant="outline"
      >
        <svg
          fill="#232E63"
          height="20px"
          width="20px"
          className="mr-2"
          viewBox="0 0 498.436 498.436"
        >
          <g>
            <g>
              <g>
                <path
                  d="M389.277,0H74.15v68.25H22.941v181.13h51.187v249.056h401.368V80.653L389.277,0z M293.805,151.125v14.841H257.07v37.4
				h-17.731v-87.987h60.355v14.884H257.07v20.773H293.805z M224.261,160.314c0,7.248-0.906,13.482-2.696,18.723
				c-2.2,6.385-5.371,11.584-9.405,15.531c-3.128,3.063-7.226,5.371-12.425,7.032c-4.012,1.23-9.232,1.877-15.747,1.877h-33.392
				v-88.009h32.399c7.312,0,12.921,0.539,16.76,1.661c5.134,1.553,9.534,4.228,13.201,8.111c3.689,3.883,6.471,8.585,8.434,14.258
				C223.312,145.171,224.261,152.052,224.261,160.314z M86.359,170.258v33.241H68.627v-0.022v-88.009h28.452
				c10.785,0,17.817,0.453,21.053,1.337c5.004,1.337,9.297,4.185,12.64,8.542c3.451,4.465,5.134,10.181,5.134,17.127
				c0,5.436-0.971,9.923-2.934,13.611c-1.941,3.753-4.422,6.601-7.485,8.736c-2.955,2.049-5.997,3.473-9.103,4.12
				c-4.293,0.82-10.354,1.316-18.4,1.316H86.359z M446.375,469.186c-25.842,0-317.306,0-343.105,0c0-13.223,0-116.482,0-219.806
				h244.613V68.25H103.292c0-20.535,0-34.751,0-39.108c25.756,0,263.983,0,274.531,0c6.989,6.601,60.873,56.968,68.595,64.151
				C446.375,105.136,446.375,442.654,446.375,469.186z"
                />
                <path
                  d="M199.066,135.357c-2.157-2.071-4.875-3.408-8.175-4.12c-2.545-0.582-7.334-0.863-14.539-0.863h-7.981v58.22h13.201
				c4.94,0,8.499-0.259,10.721-0.841c2.912-0.712,5.285-1.985,7.161-3.667c1.941-1.726,3.451-4.53,4.681-8.456
				c1.186-3.969,1.812-9.362,1.812-16.135c0-6.73-0.604-12.015-1.812-15.596C202.884,140.232,201.201,137.406,199.066,135.357z"
                />
                <path
                  d="M114.724,134.882c-1.855-2.071-4.249-3.343-7.118-3.904c-2.049-0.41-6.363-0.604-12.705-0.604h-8.542v24.957h9.621
				c7.01,0,11.648-0.431,13.999-1.381c2.373-0.906,4.228-2.33,5.587-4.293c1.337-1.963,1.963-4.228,1.963-6.86
				C117.55,139.628,116.687,136.996,114.724,134.882z"
                />
              </g>
            </g>
          </g>
        </svg>
        Open PDF
      </Button>
    </>
  )
}

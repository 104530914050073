import React from 'react'

interface LogoProps {
  className?: string
  fill?: string
  width?: number
}

export const Logo = ({ className, fill = 'none', width = 50 }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1280 997.32"
      className={className}
      width={width}
    >
      <g>
        <path
          fill={fill}
          d="m129.8,867.62c-6.28,3.96-13.65,7.3-22.11,10.03-8.47,2.73-17.4,4.09-26.82,4.09-11.88,0-22.76-1.95-32.66-5.83-9.9-3.89-18.43-9.29-25.59-16.18s-12.73-15.05-16.69-24.46c-3.96-9.42-5.94-19.73-5.94-30.92s2.01-21.32,6.04-30.81c4.02-9.49,9.62-17.71,16.79-24.67,7.17-6.96,15.69-12.38,25.59-16.27,9.89-3.89,20.71-5.83,32.45-5.83,9.42,0,18.22,1.3,26.41,3.89,8.19,2.6,15.49,5.88,21.91,9.83l-8.8,21.7c-6.01-3.41-12.49-6.18-19.45-8.29-6.96-2.12-13.79-3.17-20.47-3.17-8.19,0-15.56,1.3-22.11,3.89s-12.12,6.24-16.69,10.95c-4.57,4.71-8.09,10.38-10.54,16.99-2.46,6.62-3.69,13.89-3.69,21.8s1.26,15.16,3.79,21.7c2.53,6.55,6.11,12.18,10.75,16.89,4.64,4.71,10.27,8.36,16.89,10.96s14.02,3.89,22.21,3.89c6.69,0,13.65-1.1,20.88-3.28s13.92-5.05,20.07-8.6l7.78,21.7Z"
        />
        <path
          fill={fill}
          d="m260.42,879.29l-14.53-34.39h-59.37l-14.53,34.39h-26.41l69.2-152.12h3.69l68.99,152.12h-27.02Zm-44.22-104.82l-21.29,50.16h42.58l-21.29-50.16Z"
        />
        <path
          fill={fill}
          d="m438.34,879.29l-2.05-89.88-47.29,71.66h-3.07l-47.7-71.86-1.64,90.08h-25.18l6.35-151.91,3.89-.41,66.54,99.5,64.9-99.5,4.09.41,6.34,151.91h-25.18Z"
        />
        <path
          fill={fill}
          d="m606.02,837.53c0,5.6-1.3,10.98-3.89,16.18-2.6,5.18-6.45,9.79-11.57,13.81-5.12,4.03-11.47,7.24-19.04,9.62-7.57,2.39-16.41,3.58-26.51,3.58-6.42,0-13.14-.27-20.17-.82-7.03-.55-13.82-1.23-20.37-2.05v-147c5.59-.82,11.88-1.5,18.84-2.05,6.96-.55,13.51-.82,19.66-.82,9.82,0,18.43,1.09,25.79,3.27,7.37,2.19,13.51,5.12,18.43,8.8,4.91,3.69,8.6,7.88,11.05,12.59,2.46,4.71,3.69,9.59,3.69,14.64,0,7.92-2.02,14.71-6.04,20.37-4.02,5.67-10.75,10.27-20.17,13.82,11.06,3.14,18.87,7.85,23.44,14.13,4.57,6.27,6.86,13.58,6.86,21.91Zm-30.5-65.93c0-6.27-2.32-11.42-6.96-15.46-4.64-4.02-11.67-6.04-21.09-6.04-3.14,0-6.25.14-9.31.41-3.07.27-5.91.61-8.5,1.02v41.56c3.68.41,8.73.61,15.15.61,5.46,0,10.14-.58,14.02-1.74s7.06-2.73,9.52-4.71,4.26-4.3,5.43-6.96c1.16-2.66,1.74-5.56,1.74-8.71Zm3.07,63.27c0-6.55-2.8-12.01-8.4-16.38s-13.92-6.55-24.98-6.55c-2.73,0-5.56.03-8.5.1s-5.29.24-7.06.51v45.04c2.59.28,5.39.51,8.39.72,3,.2,6.28.31,9.83.31,10.24,0,17.91-2.18,23.03-6.55,5.12-4.37,7.68-10.1,7.68-17.2Z"
        />
        <path
          fill={fill}
          d="m722.3,879.29l-32.75-54.05c-1.37.14-2.66.2-3.89.2h-4.09c-2.46,0-4.95-.03-7.47-.1s-5.01-.17-7.47-.31v54.25h-25.59v-148.64c6-.68,12.18-1.26,18.53-1.74,6.34-.48,13.34-.71,20.98-.71,10.65,0,19.82,1.23,27.54,3.68,7.71,2.46,14.09,5.81,19.14,10.03,5.05,4.23,8.77,9.21,11.16,14.95,2.39,5.73,3.58,11.94,3.58,18.63,0,9.82-2.53,18.5-7.57,26-5.05,7.51-12.28,13.31-21.7,17.41l39.31,60.39h-29.69Zm-7.37-102.57c0-7.64-2.73-13.82-8.19-18.53-5.46-4.71-13.1-7.06-22.93-7.06-3.14,0-6.14.08-9.01.21-2.86.13-5.6.48-8.19,1.02v51.18c2.32.28,4.74.48,7.27.61,2.52.14,5.01.2,7.47.2,11.19,0,19.58-2.45,25.18-7.36,5.6-4.92,8.4-11.68,8.4-20.27Z"
        />
        <path fill={fill} d="m781.68,879.29v-150.07h25.59v150.07h-25.59Z" />
        <path
          fill={fill}
          d="m981.7,802.93c0,10.52-1.78,20.47-5.32,29.89-3.55,9.42-8.91,17.64-16.07,24.67s-16.21,12.63-27.13,16.78c-10.92,4.16-23.82,6.25-38.7,6.25-4.09,0-7.95-.07-11.56-.21-3.62-.13-7.13-.3-10.54-.51-3.41-.2-6.83-.44-10.24-.71-3.41-.27-7.03-.61-10.85-1.03v-147.41c7.37-.82,14.3-1.43,20.78-1.84,6.48-.41,13.48-.61,20.98-.61,14.88,0,27.85,1.98,38.9,5.94,11.05,3.96,20.27,9.35,27.64,16.17,7.37,6.83,12.9,14.78,16.58,23.86,3.69,9.08,5.53,18.66,5.53,28.76Zm-27.44,0c0-7.78-1.23-14.88-3.68-21.29-2.46-6.41-6.14-11.91-11.06-16.48-4.91-4.57-10.99-8.11-18.22-10.64-7.24-2.52-15.63-3.79-25.18-3.79-7.51,0-13.92.41-19.24,1.23v104.62c3.14.55,6.27.89,9.42,1.02,3.14.14,7.17.21,12.08.21,9.69,0,18.01-1.36,24.98-4.09,6.96-2.73,12.76-6.55,17.4-11.47,4.64-4.91,8.05-10.71,10.24-17.41,2.18-6.69,3.27-13.99,3.27-21.91Z"
        />
        <path
          fill={fill}
          d="m1149.99,799.86v65.52c-8.06,5.18-16.55,9.21-25.49,12.07-8.94,2.86-19.08,4.3-30.4,4.3-10.65,0-20.82-1.88-30.5-5.63-9.7-3.75-18.15-9.01-25.39-15.76s-13-14.88-17.3-24.37c-4.3-9.48-6.45-20.03-6.45-31.63s2.18-22.35,6.55-31.84c4.37-9.48,10.2-17.64,17.5-24.46s15.77-12.08,25.39-15.76c9.62-3.69,19.68-5.53,30.2-5.53,11.05,0,20.78,1.4,29.17,4.19,8.4,2.8,16.41,6.59,24.06,11.37l-11.67,20.47c-6.14-3.96-12.66-7.03-19.55-9.22-6.89-2.18-13.82-3.28-20.78-3.28-8.6,0-16.21,1.4-22.83,4.2-6.62,2.8-12.18,6.65-16.69,11.56-4.5,4.92-7.95,10.65-10.34,17.2-2.39,6.55-3.58,13.58-3.58,21.09,0,7.92,1.36,15.25,4.09,22.01,2.73,6.76,6.52,12.52,11.36,17.3s10.51,8.53,16.99,11.26c6.48,2.73,13.41,4.09,20.78,4.09,6.55,0,12.15-.58,16.79-1.74,4.64-1.15,9.14-2.97,13.51-5.42v-29.48h-35.01v-22.52h59.58Z"
        />
        <path
          fill={fill}
          d="m1188.48,879.29v-150.07h91.52v23.35h-65.93v39.31h59.58v22.52h-59.58v41.56h65.93v23.34h-91.52Z"
        />
      </g>
      <g>
        <path
          fill={fill}
          d="m313.77,946.15c0-2.76,2.12-4.88,4.88-4.88h19.84c6.3,0,11.26,1.73,14.41,4.88,2.44,2.44,3.7,5.43,3.7,9.13v.16c0,6.61-3.78,10.15-7.87,12.28,6.45,2.21,10.94,5.9,10.94,13.38v.16c0,9.84-8.11,15.11-20.39,15.11h-20.62c-2.76,0-4.88-2.12-4.88-4.88v-45.34Zm22.75,18.34c6.22,0,10.39-2.44,10.39-7.48v-.15c0-4.33-3.46-7.01-9.68-7.01h-13.93v14.64h13.22Zm2.83,23.3c6.61,0,10.63-2.6,10.63-7.55v-.16c0-4.65-3.7-7.4-11.33-7.4h-15.35v15.11h16.06Z"
        />
        <path
          fill={fill}
          d="m376.02,968.98v-.16c0-15.51,11.97-28.5,28.89-28.5s28.73,12.83,28.73,28.33v.16c0,15.51-11.97,28.5-28.89,28.5s-28.73-12.83-28.73-28.33Zm47.47,0v-.16c0-10.71-7.79-19.6-18.74-19.6s-18.58,8.73-18.58,19.44v.16c0,10.71,7.79,19.52,18.73,19.52s18.58-8.66,18.58-19.36Z"
        />
        <path
          fill={fill}
          d="m453.24,945.83c0-2.68,2.12-4.88,4.88-4.88h1.02c2.36,0,3.7,1.18,5.04,2.84l27.55,35.66v-33.85c0-2.6,2.12-4.72,4.72-4.72s4.8,2.12,4.8,4.72v46.29c0,2.68-2.05,4.8-4.72,4.8h-.39c-2.28,0-3.7-1.18-5.04-2.91l-28.34-36.69v34.95c0,2.6-2.12,4.72-4.72,4.72s-4.8-2.12-4.8-4.72v-46.21Z"
        />
        <path
          fill={fill}
          d="m523.29,991.49v-45.34c0-2.76,2.12-4.88,4.88-4.88h32.04c2.36,0,4.33,1.97,4.33,4.33s-1.97,4.33-4.33,4.33h-27.24v14.33h23.7c2.36,0,4.33,1.96,4.33,4.4s-1.97,4.26-4.33,4.26h-23.7v14.8h27.63c2.36,0,4.33,1.96,4.33,4.33s-1.97,4.33-4.33,4.33h-32.43c-2.76,0-4.88-2.12-4.88-4.88Z"
        />
        <path
          fill={fill}
          d="m628.76,972.99h-11.18c-2.36,0-4.33-1.89-4.33-4.33s1.97-4.33,4.33-4.33h11.18v-10.94c0-2.52,1.97-4.49,4.49-4.49s4.49,1.96,4.49,4.49v10.94h11.18c2.36,0,4.33,1.97,4.33,4.33s-1.97,4.33-4.33,4.33h-11.18v10.94c0,2.52-2.05,4.49-4.49,4.49s-4.49-1.96-4.49-4.49v-10.94Z"
        />
        <path
          fill={fill}
          d="m701.8,991.1c-.87-.78-1.65-2.12-1.65-3.62,0-2.6,2.04-4.65,4.64-4.65,1.26,0,2.12.4,2.99,1.1,2.68,2.75,5.28,4.25,8.98,4.25,5.35,0,8.9-3.46,8.9-10.86v-31.65c0-2.68,2.2-4.8,4.88-4.8s4.88,2.12,4.88,4.8v31.96c0,6.53-1.97,11.41-5.2,14.64-3.22,3.23-7.87,4.88-13.38,4.88-6.69,0-11.57-2.6-15.03-6.07Z"
        />
        <path
          fill={fill}
          d="m754.14,968.98v-.16c0-15.51,11.97-28.5,28.89-28.5s28.73,12.83,28.73,28.33v.16c0,15.51-11.97,28.5-28.89,28.5s-28.73-12.83-28.73-28.33Zm47.47,0v-.16c0-10.71-7.79-19.6-18.74-19.6s-18.58,8.73-18.58,19.44v.16c0,10.71,7.79,19.52,18.73,19.52s18.58-8.66,18.58-19.36Z"
        />
        <path
          fill={fill}
          d="m831.9,945.68c0-2.68,2.12-4.8,4.88-4.8s4.8,2.12,4.8,4.8v46.29c0,2.68-2.12,4.8-4.8,4.8s-4.88-2.12-4.88-4.8v-46.29Z"
        />
        <path
          fill={fill}
          d="m864.17,945.83c0-2.68,2.12-4.88,4.88-4.88h1.02c2.36,0,3.7,1.18,5.04,2.84l27.55,35.66v-33.85c0-2.6,2.12-4.72,4.72-4.72s4.8,2.12,4.8,4.72v46.29c0,2.68-2.05,4.8-4.72,4.8h-.39c-2.28,0-3.7-1.18-5.04-2.91l-28.34-36.69v34.95c0,2.6-2.12,4.72-4.72,4.72s-4.8-2.12-4.8-4.72v-46.21Z"
        />
        <path
          fill={fill}
          d="m947.76,950.24h-13.38c-2.52,0-4.49-2.05-4.49-4.49s1.97-4.49,4.49-4.49h36.6c2.44,0,4.41,2.05,4.41,4.49s-1.97,4.49-4.41,4.49h-13.46v41.72c0,2.68-2.2,4.8-4.88,4.8s-4.88-2.12-4.88-4.8v-41.72Z"
        />
      </g>
      <g>
        <g>
          <path
            fill={fill}
            d="m782.31,447.69c.41-.46.65-.73.65-.73-.21.25-.44.48-.65.73Z"
          />
          <path
            fill={fill}
            d="m782.31,447.69c-4.26,4.76-29.97,32.3-67.05,49.19-16.38,6.09-32.66,10.58-48.89,13-5.26.38-10.12.93-14.65,1.6-54.42,3.76-108.59-17.52-165.02-82.38l-.94-1.26c-64.69-86.69-54.6-202.52,29.55-265.31,40.46-30.19,82.59-40.06,128.77-39.16l56.01,19.67c6.59,3.24,14.46,2.25,20.05-2.5l22.15-18.83c7.67-6.53,8.69-17.99,2.27-25.76-6.89-8.33-14.17-17.58-13.74-18.96.56-1.81,6.6-22.6,10.16-34.9,1.64-5.66.47-11.72-3.14-16.37l-12.31-15.89c-1.41-1.82-3.28-3.25-5.4-4.14l-10.17-4.27c-6.1-2.56-13.11-1.65-18.36,2.39l-31.63,24.34c-2.8-.53-5.59-.86-8.27-.89-60.78-.94-120.78,12.33-189.11,63.32-134.1,100.07-151.42,273.51-57.48,399.4l.94,1.26c58.43,55.3,126.43,69.51,186.97,61.25,0,0,0,.04,0,.04,0,0,.56-.09,1.56-.26,27.67-3.96,53.72-12.59,76.55-24.15,35.67-15.74,77.37-40.91,111.19-80.45Z"
          />
        </g>
        <path
          fill={fill}
          d="m945.43,338.86l-10.51-27.11c-3.64-9.39-14.14-14.12-23.58-10.6-10.13,3.77-21.26,7.61-22.42,6.75-1.52-1.12-19.18-13.66-29.62-21.07-4.8-3.41-10.91-4.29-16.49-2.41l-19.05,6.41c-2.19.74-4.15,2.02-5.69,3.74l-7.37,8.2c-4.42,4.92-5.87,11.84-3.78,18.12l13.81,41.52c1.24,3.73,1.26,7.76.04,11.5l-4.07,33.44c-9.39,23.52-22.5,44.27-41.76,63.51,2.49-2.43,4.4-4.41,5.74-5.85.53-.61,1.1-1.16,1.63-1.78-.37.41-.95,1.05-1.63,1.78-33.53,38.47-74.45,63.17-109.56,78.67-22.83,11.56-48.88,20.19-76.55,24.15-1,.17-1.56.26-1.56.26,0,0,0-.03,0-.04-60.54,8.26-128.53-5.95-186.97-61.25,94.88,127.15,266.1,155.99,395.15,59.69,70.86-52.88,101.37-110.5,115.89-177.58l24.12-30.28c5.22-5.16,6.88-12.92,4.23-19.76Z"
        />
      </g>
    </svg>
  )
}

export const HorizontalLogo = ({
  className,
  fill = 'none',
  width = 50
}: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1280 300"
      className={className}
      width={width}
      fill={fill}
    >
      <g>
        <g>
          <path d="m423.89,148.51c-4.67,2.95-10.16,5.44-16.46,7.47-6.3,2.03-12.95,3.05-19.96,3.05-8.84,0-16.94-1.45-24.31-4.34-7.37-2.9-13.71-6.91-19.05-12.04-5.33-5.13-9.47-11.2-12.42-18.21-2.95-7.01-4.42-14.68-4.42-23.01s1.5-15.87,4.5-22.93c3-7.06,7.16-13.18,12.5-18.36,5.33-5.18,11.68-9.22,19.05-12.11,7.36-2.9,15.41-4.34,24.15-4.34,7.01,0,13.56.97,19.66,2.9,6.1,1.93,11.53,4.37,16.31,7.31l-6.55,16.15c-4.47-2.54-9.3-4.6-14.48-6.17-5.18-1.57-10.26-2.36-15.24-2.36-6.1,0-11.58.97-16.46,2.9-4.88,1.93-9.02,4.65-12.42,8.15-3.4,3.51-6.02,7.72-7.85,12.65-1.83,4.93-2.74,10.34-2.74,16.23s.94,11.28,2.82,16.15c1.88,4.88,4.55,9.07,8,12.57,3.45,3.5,7.64,6.22,12.57,8.15,4.93,1.93,10.44,2.9,16.53,2.9,4.98,0,10.16-.81,15.54-2.44,5.38-1.62,10.36-3.76,14.93-6.4l5.79,16.15Z" />
          <path d="m521.11,157.2l-10.82-25.6h-44.19l-10.82,25.6h-19.66l51.51-113.22h2.74l51.36,113.22h-20.12Zm-32.92-78.02l-15.85,37.34h31.7l-15.85-37.34Z" />
          <path d="m653.54,157.2l-1.52-66.9-35.2,53.34h-2.29l-35.51-53.49-1.22,67.05h-18.74l4.72-113.07,2.9-.3,49.53,74.06,48.31-74.06,3.05.3,4.72,113.07h-18.74Z" />
          <path d="m778.34,126.11c0,4.17-.97,8.18-2.9,12.04-1.93,3.86-4.8,7.29-8.61,10.29-3.81,3-8.53,5.39-14.17,7.16-5.64,1.78-12.22,2.67-19.73,2.67-4.78,0-9.78-.2-15.01-.61-5.23-.41-10.29-.91-15.16-1.52V46.72c4.16-.61,8.84-1.12,14.02-1.52,5.18-.4,10.06-.61,14.63-.61,7.31,0,13.71.81,19.2,2.44,5.49,1.63,10.06,3.81,13.72,6.55,3.66,2.74,6.4,5.87,8.23,9.37,1.83,3.5,2.74,7.14,2.74,10.9,0,5.89-1.5,10.95-4.5,15.16-3,4.22-8,7.65-15.01,10.29,8.23,2.34,14.04,5.84,17.45,10.51,3.4,4.67,5.11,10.11,5.11,16.31Zm-22.71-49.07c0-4.67-1.73-8.51-5.18-11.51-3.46-3-8.69-4.5-15.7-4.5-2.34,0-4.65.1-6.93.3-2.29.2-4.4.46-6.32.76v30.93c2.74.3,6.5.46,11.28.46,4.06,0,7.54-.43,10.44-1.3,2.9-.86,5.26-2.03,7.09-3.5,1.83-1.47,3.17-3.2,4.04-5.18.86-1.98,1.3-4.14,1.3-6.48Zm2.29,47.09c0-4.88-2.08-8.94-6.25-12.19-4.17-3.25-10.36-4.88-18.59-4.88-2.03,0-4.14.03-6.32.08-2.19.05-3.94.18-5.26.38v33.53c1.93.2,4.01.38,6.25.53,2.23.15,4.67.23,7.31.23,7.62,0,13.33-1.62,17.14-4.88,3.81-3.25,5.71-7.52,5.71-12.8Z" />
          <path d="m864.9,157.2l-24.38-40.23c-1.02.1-1.98.15-2.9.15h-3.05c-1.83,0-3.68-.02-5.56-.08-1.88-.05-3.73-.13-5.56-.23v40.38h-19.05V46.56c4.47-.51,9.07-.94,13.79-1.3,4.72-.35,9.93-.53,15.62-.53,7.92,0,14.76.91,20.5,2.74,5.74,1.83,10.49,4.32,14.25,7.47,3.76,3.15,6.53,6.86,8.31,11.12,1.78,4.27,2.67,8.89,2.67,13.87,0,7.31-1.88,13.77-5.64,19.35-3.76,5.59-9.14,9.91-16.15,12.95l29.26,44.95h-22.1Zm-5.49-76.35c0-5.69-2.03-10.29-6.1-13.79-4.06-3.5-9.75-5.26-17.07-5.26-2.34,0-4.57.05-6.7.15-2.13.1-4.17.36-6.1.76v38.1c1.73.2,3.53.36,5.41.46,1.88.1,3.73.15,5.56.15,8.33,0,14.58-1.83,18.74-5.49,4.16-3.66,6.25-8.69,6.25-15.09Z" />
          <path d="m909.09,157.2V45.5h19.05v111.7h-19.05Z" />
          <path d="m1057.97,100.36c0,7.82-1.32,15.24-3.96,22.25-2.64,7.01-6.63,13.13-11.96,18.36-5.33,5.23-12.06,9.4-20.19,12.5-8.13,3.1-17.73,4.65-28.8,4.65-3.05,0-5.92-.05-8.61-.15-2.69-.1-5.31-.23-7.85-.38-2.54-.15-5.08-.33-7.62-.53-2.54-.2-5.23-.46-8.08-.76V46.56c5.49-.61,10.64-1.07,15.47-1.37,4.82-.3,10.03-.46,15.62-.46,11.07,0,20.72,1.47,28.95,4.42,8.23,2.95,15.09,6.96,20.57,12.04,5.49,5.08,9.6,11,12.34,17.75,2.74,6.76,4.11,13.89,4.11,21.41Zm-20.42,0c0-5.79-.91-11.07-2.74-15.85-1.83-4.77-4.57-8.86-8.23-12.27-3.66-3.4-8.18-6.04-13.56-7.92-5.39-1.88-11.63-2.82-18.74-2.82-5.59,0-10.36.3-14.32.91v77.87c2.34.41,4.67.66,7.01.76,2.34.1,5.33.15,8.99.15,7.21,0,13.41-1.01,18.59-3.05,5.18-2.03,9.5-4.88,12.95-8.53,3.45-3.66,5.99-7.97,7.62-12.95,1.62-4.98,2.44-10.41,2.44-16.31Z" />
          <path d="m1183.23,98.07v48.76c-6,3.86-12.32,6.86-18.97,8.99-6.65,2.13-14.2,3.2-22.63,3.2-7.92,0-15.49-1.4-22.71-4.19-7.21-2.79-13.51-6.7-18.9-11.73-5.39-5.03-9.68-11.07-12.88-18.13-3.2-7.06-4.8-14.91-4.8-23.54s1.62-16.63,4.88-23.7c3.25-7.06,7.59-13.13,13.03-18.21,5.43-5.08,11.73-8.99,18.9-11.73,7.16-2.74,14.65-4.11,22.48-4.11,8.23,0,15.47,1.04,21.71,3.12,6.25,2.08,12.22,4.9,17.91,8.46l-8.69,15.24c-4.57-2.95-9.42-5.23-14.55-6.86-5.13-1.62-10.29-2.44-15.47-2.44-6.4,0-12.06,1.04-16.99,3.12-4.93,2.08-9.07,4.95-12.42,8.61-3.35,3.66-5.92,7.92-7.7,12.8-1.78,4.88-2.67,10.11-2.67,15.7,0,5.89,1.01,11.35,3.05,16.38,2.03,5.03,4.85,9.32,8.46,12.88,3.6,3.56,7.82,6.35,12.65,8.38,4.82,2.03,9.98,3.05,15.47,3.05,4.88,0,9.04-.43,12.5-1.3,3.45-.86,6.81-2.21,10.06-4.04v-21.94h-26.06v-16.76h44.35Z" />
          <path d="m1211.88,157.2V45.5h68.12v17.37h-49.07v29.26h44.35v16.76h-44.35v30.93h49.07v17.37h-68.12Z" />
        </g>
        <g>
          <path d="m324.17,206.96c0-2.05,1.58-3.63,3.63-3.63h14.76c4.69,0,8.38,1.29,10.72,3.63,1.82,1.82,2.75,4.04,2.75,6.8v.12c0,4.92-2.81,7.56-5.86,9.14,4.8,1.64,8.14,4.39,8.14,9.96v.12c0,7.32-6.03,11.25-15.17,11.25h-15.35c-2.05,0-3.63-1.58-3.63-3.63v-33.75Zm16.93,13.65c4.63,0,7.73-1.82,7.73-5.57v-.12c0-3.22-2.58-5.21-7.21-5.21h-10.37v10.9h9.84Zm2.11,17.34c4.92,0,7.91-1.93,7.91-5.62v-.12c0-3.46-2.75-5.51-8.44-5.51h-11.43v11.25h11.95Z" />
          <path d="m370.51,223.95v-.12c0-11.54,8.91-21.21,21.5-21.21s21.39,9.55,21.39,21.09v.12c0,11.54-8.91,21.21-21.5,21.21s-21.39-9.55-21.39-21.09Zm35.33,0v-.12c0-7.97-5.8-14.59-13.94-14.59s-13.83,6.5-13.83,14.47v.12c0,7.97,5.8,14.53,13.94,14.53s13.83-6.44,13.83-14.41Z" />
          <path d="m427.98,206.73c0-1.99,1.58-3.63,3.63-3.63h.76c1.76,0,2.75.88,3.75,2.11l20.51,26.54v-25.19c0-1.93,1.58-3.52,3.52-3.52s3.57,1.58,3.57,3.52v34.45c0,1.99-1.52,3.57-3.52,3.57h-.29c-1.7,0-2.75-.88-3.75-2.17l-21.09-27.3v26.01c0,1.93-1.58,3.52-3.52,3.52s-3.57-1.58-3.57-3.52v-34.39Z" />
          <path d="m480.12,240.71v-33.75c0-2.05,1.58-3.63,3.63-3.63h23.85c1.76,0,3.22,1.46,3.22,3.22s-1.46,3.22-3.22,3.22h-20.27v10.66h17.64c1.76,0,3.22,1.46,3.22,3.28s-1.46,3.16-3.22,3.16h-17.64v11.01h20.57c1.76,0,3.22,1.46,3.22,3.22s-1.46,3.22-3.22,3.22h-24.14c-2.05,0-3.63-1.58-3.63-3.63Z" />
          <path d="m558.62,226.94h-8.32c-1.76,0-3.22-1.41-3.22-3.22s1.46-3.22,3.22-3.22h8.32v-8.14c0-1.87,1.46-3.34,3.34-3.34s3.34,1.46,3.34,3.34v8.14h8.32c1.76,0,3.22,1.46,3.22,3.22s-1.46,3.22-3.22,3.22h-8.32v8.14c0,1.87-1.52,3.34-3.34,3.34s-3.34-1.46-3.34-3.34v-8.14Z" />
          <path d="m612.98,240.42c-.64-.59-1.23-1.58-1.23-2.7,0-1.93,1.52-3.46,3.46-3.46.94,0,1.58.29,2.23.82,1.99,2.05,3.93,3.16,6.68,3.16,3.98,0,6.62-2.58,6.62-8.09v-23.55c0-1.99,1.64-3.57,3.63-3.57s3.63,1.58,3.63,3.57v23.79c0,4.86-1.46,8.5-3.87,10.9s-5.86,3.63-9.96,3.63c-4.98,0-8.61-1.93-11.19-4.51Z" />
          <path d="m651.94,223.95v-.12c0-11.54,8.91-21.21,21.5-21.21s21.39,9.55,21.39,21.09v.12c0,11.54-8.91,21.21-21.5,21.21s-21.39-9.55-21.39-21.09Zm35.33,0v-.12c0-7.97-5.8-14.59-13.94-14.59s-13.83,6.5-13.83,14.47v.12c0,7.97,5.8,14.53,13.94,14.53s13.83-6.44,13.83-14.41Z" />
          <path d="m709.82,206.61c0-1.99,1.58-3.57,3.63-3.57s3.57,1.58,3.57,3.57v34.45c0,1.99-1.58,3.57-3.57,3.57s-3.63-1.58-3.63-3.57v-34.45Z" />
          <path d="m733.84,206.73c0-1.99,1.58-3.63,3.63-3.63h.76c1.76,0,2.75.88,3.75,2.11l20.51,26.54v-25.19c0-1.93,1.58-3.52,3.52-3.52s3.57,1.58,3.57,3.52v34.45c0,1.99-1.52,3.57-3.52,3.57h-.29c-1.7,0-2.75-.88-3.75-2.17l-21.09-27.3v26.01c0,1.93-1.58,3.52-3.52,3.52s-3.57-1.58-3.57-3.52v-34.39Z" />
          <path d="m796.06,210.01h-9.96c-1.87,0-3.34-1.52-3.34-3.34s1.46-3.34,3.34-3.34h27.24c1.82,0,3.28,1.52,3.28,3.34s-1.46,3.34-3.28,3.34h-10.02v31.05c0,1.99-1.64,3.57-3.63,3.57s-3.63-1.58-3.63-3.57v-31.05Z" />
        </g>
      </g>
      <g>
        <g>
          <path d="m199.47,204.68c.19-.21.3-.34.3-.34-.1.11-.2.22-.3.34Z" />
          <path d="m199.47,204.68c-1.95,2.18-13.7,14.77-30.65,22.49-7.49,2.78-14.93,4.84-22.35,5.94-2.41.18-4.63.43-6.7.73-24.88,1.72-49.65-8.01-75.45-37.66l-.43-.58c-29.57-39.63-24.96-92.59,13.51-121.3,18.5-13.8,37.76-18.31,58.87-17.9l25.61,8.99c3.01,1.48,6.61,1.03,9.17-1.14l10.13-8.61c3.51-2.98,3.97-8.23,1.04-11.78-3.15-3.81-6.48-8.04-6.28-8.67.26-.83,3.02-10.33,4.65-15.96.75-2.59.21-5.36-1.44-7.48l-5.63-7.26c-.65-.83-1.5-1.49-2.47-1.9l-4.65-1.95c-2.79-1.17-6-.75-8.39,1.09l-14.46,11.13c-1.28-.24-2.55-.39-3.78-.41-27.79-.43-55.22,5.64-86.46,28.95C-8.02,87.17-15.94,166.46,27.01,224.02l.43.58c26.71,25.28,57.8,31.78,85.48,28,0,0,0,.02,0,.02,0,0,.26-.04.71-.12,12.65-1.81,24.56-5.76,35-11.04,16.31-7.2,35.37-18.7,50.84-36.78Z" />
        </g>
        <path d="m274.04,154.92l-4.8-12.39c-1.66-4.29-6.46-6.45-10.78-4.85-4.63,1.72-9.72,3.48-10.25,3.09-.7-.51-8.77-6.24-13.54-9.63-2.2-1.56-4.99-1.96-7.54-1.1l-8.71,2.93c-1,.34-1.9.93-2.6,1.71l-3.37,3.75c-2.02,2.25-2.68,5.41-1.73,8.29l6.31,18.98c.57,1.71.57,3.55.02,5.26l-1.86,15.29c-4.29,10.75-10.29,20.24-19.09,29.04,1.14-1.11,2.01-2.02,2.63-2.67.24-.28.5-.53.75-.81-.17.19-.44.48-.75.81-15.33,17.59-34.04,28.88-50.09,35.97-10.44,5.28-22.35,9.23-35,11.04-.46.08-.71.12-.71.12,0,0,0-.01,0-.02-27.68,3.78-58.76-2.72-85.48-28,43.38,58.13,121.66,71.32,180.66,27.29,32.4-24.17,46.34-50.52,52.98-81.19l11.03-13.84c2.39-2.36,3.15-5.91,1.93-9.03Z" />
      </g>
    </svg>
  )
}
